@font-face {
   font-family: GothamNarrow-Bold;
   src: url("./fonts/GothamNarrow-Bold.otf");
}

@font-face {
   font-family: GothamNarrow-Book;
   src: url("./fonts/GothamNarrow-Book.otf");
}

@font-face {
   font-family: GothamNarrow-Medium;
   src: url("./fonts/GothamNarrow-Medium.otf");
}

@font-face {
   font-family: GothamNarrow-Light;
   src: url("./fonts/GothamNarrow-Light.otf");
}

@font-face {
   font-family: Beleren2016-Bold;
   src: url("./fonts/Beleren2016-Bold.woff");
}
