@import url("https://fonts.googleapis.com/css2?family=Antonio:wght@100..700&display=swap");

* {
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -o-user-select: none;
   user-select: none;
}

input,
textarea {
   -webkit-user-select: text;
   -khtml-user-select: text;
   -moz-user-select: text;
   -o-user-select: text;
   user-select: text;
}

body {
   margin: 0;
   min-width: 320px;
   min-height: 100vh;
   background-color: #000 !important;
   overflow: hidden;
}

iframe {
   border: none;
}

.wrapper {
   box-sizing: border-box;
   width: 1920px;
   height: 1080px;
   margin: 0;
   padding: 0;
   overflow: hidden;
   position: relative;
   margin-top: 2em;
}

.running {
   box-sizing: border-box;
   border: 10px rgba(255, 0, 0, 0.8) solid;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: 1000;
}

h1 {
   font-size: 12em !important;
   font-weight: 700 !important;
   color: #fff;
   padding: 0;
   line-height: 1em;
   margin: 0;
   text-transform: uppercase;
}

h2 {
   font-size: 5em !important;
   font-weight: 700 !important;
   opacity: 0.8;
   color: #fff;
   padding: 0;
   line-height: 1em;
   margin: 0;
   text-transform: uppercase;
}

h3 {
   font-size: 3em !important;
   text-align: center;
   opacity: 0.8;
   color: #fff;
   padding: 0;
   line-height: 1em;
   margin: 0;
}

h6 {
   font-size: 0.5em !important;
   font-weight: 700 !important;
   opacity: 0.5;
   color: #fff;
   padding: 0;
   line-height: 1em;
   margin: 0;
   text-transform: uppercase;
   text-align: center;
}

.overtime {
   color: #f00;
}

.inputLight {
   background: rgba(232, 241, 250, 0.2) !important;
   width: 50%;
}

.previewTime {
   font-size: 3px;
   padding: 0;
   margin: 0;
}
